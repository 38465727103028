import * as _resizeObserverPolyfill4 from "resize-observer-polyfill";
var _resizeObserverPolyfill3 = "default" in _resizeObserverPolyfill4 ? _resizeObserverPolyfill4.default : _resizeObserverPolyfill4;
import * as _throttleDebounce3 from "throttle-debounce";
var _throttleDebounce2 = "default" in _throttleDebounce3 ? _throttleDebounce3.default : _throttleDebounce3;
var exports = {};
exports.__esModule = true;
exports.removeResizeListener = exports.addResizeListener = undefined;
var _resizeObserverPolyfill = _resizeObserverPolyfill3;
var _resizeObserverPolyfill2 = _interopRequireDefault(_resizeObserverPolyfill);
var _throttleDebounce = _throttleDebounce2;
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var isServer = typeof window === "undefined";

/* istanbul ignore next */
var resizeHandler = function resizeHandler(entries) {
  for (var _iterator = entries, _isArray = Array.isArray(_iterator), _i = 0, _iterator = _isArray ? _iterator : _iterator[Symbol.iterator]();;) {
    var _ref;
    if (_isArray) {
      if (_i >= _iterator.length) break;
      _ref = _iterator[_i++];
    } else {
      _i = _iterator.next();
      if (_i.done) break;
      _ref = _i.value;
    }
    var entry = _ref;
    var listeners = entry.target.__resizeListeners__ || [];
    if (listeners.length) {
      listeners.forEach(function (fn) {
        fn();
      });
    }
  }
};

/* istanbul ignore next */
var addResizeListener = exports.addResizeListener = function addResizeListener(element, fn) {
  if (isServer) return;
  if (!element.__resizeListeners__) {
    element.__resizeListeners__ = [];
    element.__ro__ = new _resizeObserverPolyfill2.default((0, _throttleDebounce.debounce)(16, resizeHandler));
    element.__ro__.observe(element);
  }
  element.__resizeListeners__.push(fn);
};

/* istanbul ignore next */
var removeResizeListener = exports.removeResizeListener = function removeResizeListener(element, fn) {
  if (!element || !element.__resizeListeners__) return;
  element.__resizeListeners__.splice(element.__resizeListeners__.indexOf(fn), 1);
  if (!element.__resizeListeners__.length) {
    element.__ro__.disconnect();
  }
};
export default exports;
export const __esModule = exports.__esModule;
const _removeResizeListener = exports.removeResizeListener,
  _addResizeListener = exports.addResizeListener;
export { _removeResizeListener as removeResizeListener, _addResizeListener as addResizeListener };